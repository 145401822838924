import Vue from 'vue'
import store from "@/store";


import loader from '@/components/creative/loader'
import checkUpdate from '@/components/creative/checkUpdate'
import elem from '@/components/creative/admin/elem'
import seo from '@/components/creative/admin/seo'
import imgUpload from '@/components/creative/admin/imgUpload'
import creativeMain from '@/components/creative/admin/creativeMain'


import axios from 'axios'
import VueMeta from 'vue-meta'
import VueTextareaAutogrowDirective from 'vue-textarea-autogrow-directive'
import numeral from 'numeral'
import { Upload } from 'element-ui'


axios.defaults.baseURL = process.env.VUE_APP_CREATIVE_API || 'http://127.0.0.1:4011'


Vue.component("loader", loader);
Vue.component("checkUpdate", checkUpdate);
Vue.component('elem', elem)
Vue.component('seo', seo)
Vue.component('imgUpload', imgUpload)
Vue.component('creativeMain', creativeMain)

Vue.use(VueMeta)
Vue.use(VueTextareaAutogrowDirective)
Vue.use(Upload)


Vue.prototype.$http = axios
Vue.prototype.$imgFormat = (key) => {
    return process.env.VUE_APP_IMG + '/img/' + key
}

let globalData = new Vue({
    data: { $canScroll: true }
});
Vue.mixin({
    computed: {
        $canScroll: {
            get: function () { return globalData.$data.$canScroll },
            set: function (newValue) { globalData.$data.$canScroll = newValue; }
        }
    }
})

store.dispatch('creativeCart/sync')
store.dispatch('creativeAuth/autoLogin')
store.dispatch('creativeEdit/autoLogin')






numeral.register("locale", "fr", {
    delimiters: {
        thousands: " ",
        decimal: ","
    },
    abbreviations: {
        thousand: "k",
        million: "m",
        billion: "b",
        trillion: "t"
    },
    ordinal: function(number) {
        return number === 1 ? "er" : "ème";
    },
    currency: {
        symbol: "€"
    }
});
numeral.locale('fr');
Vue.filter("formatNumber", function(value) {
    return numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
});
Vue.filter("formatNumberdecimal", function(value) {
    return numeral(value).format("0,0.00"); // displaying other groupings/separators is possible, look at the docs
});







const init = () => {
    return new Promise((resolve, reject) => {
        axios.get('/project').then((content) => {
            Vue.prototype.$content = content.data
            resolve()
        }).catch(() => reject())
    })
}

// const init = () => axios.get('/project')

export { init }
<template>
  <div class="rsvp" :data-formActive="formActive">
    
    <button class="start" @click="active = 1; formActive = true; $canScroll = false" v-if="!active">Confirmer ma présence</button>
    
    <form action="" v-if="active === 1" @submit.prevent="formSubmit">
      
      <h2>Confirmer ma présence</h2>
      <div class="error" v-if="error">{{ error }}</div>
      <label>Votre prénom</label>
      <input type="text" v-model="firstName" autocomplete="given-name" @change="change">
      <label>Votre nom</label>
      <input type="text" v-model="name" autocomplete="family-name" @change="change">
      <label>Votre adresse e-email</label>
      <input type="text" v-model="email" autocomplete="email" @change="change">
      <label>Votre numéro de téléphone</label>
      <vue-tel-input v-model="phone" :inputOptions="{ placeholder: 'Numéro de téléphone' }" @validate="phone_validate_method"></vue-tel-input>
      <label>Heure et lieux d'arrivée</label>
      <input type="text" v-model="heure" @change="change">
       <div class="check">
         <input type="checkbox" v-model="propreMoyens" id="propremoyens" @change="change">
         <label for="propremoyens">Par mes propres moyens</label>
       </div>
      <label>Nombre d'adultes</label>
      <input type="text" v-model="adultesNb" @change="change">
      <label>Nombre d'enfants</label>
      <input type="text" v-model="enfantsNb" @change="change">
      <label>Âge des enfants</label>
      <input type="text" v-model="enfantsAge" @change="change">
      
      <button class="bouton" :data-disabled="!buttonActive">Confirmer</button>
    </form>
    
    <div class="end" v-if="active === 2">Merci, votre présence est confirmée</div>

    <svg v-if="formActive" class="close" @click="active = 0; formActive = false; $canScroll = true" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"/></svg>
  </div>
</template>

<script>

export default {
  name: "rsvp",
  data() {
    return {
      
      active: null,
      error: null,
      
      firstName: null,  
      name: null,
      phone: null,
      email: null,
      phone_validate: null,
      
      adultesNb: null,
      enfantsNb: null,
      enfantsAge: null,
      
      heure: null,
      propreMoyens: true,
      
      buttonActive: false,
      
      formActive: false,
      
    }
  },
  methods: {
    change() {
      if(this.firstName && this.name && ((this.phone && this.phone_validate && this.phone_validate.valid === true) || this.email)) {
        this.buttonActive = true
      }
      else this.buttonActive = false
    },
    phone_validate_method(data) {
      this.phone_validate = data
    },
    formSubmit() {
      if(this.buttonActive) {
        this.buttonActive = false

        this.$http.post('/rsvp', { 
          data: {
            firstName: this.firstName,
            name: this.name,
            phone: this.phone,
            email: this.email,
            heure: this.heure,
            propreMoyens: this.propreMoyens,
            adultesNb: this.adultesNb,
            enfantsNb: this.enfantsNb,
          } 
        }).then(() => {
            this.active = 2
            this.formActive = false
            this.$canScroll = true
        }).catch((e) => {
            this.error = e.response.data.message
            this.buttonActive = false
        })
      }
      else 
        this.error = 'Il manque des informations'
    }
  }
}
</script>

<style>
  .vue-tel-input input.vti__input { margin-bottom: 0; }
  .vue-tel-input { margin-bottom: .75rem; border: 1px solid #dee2e6 }
  .vue-tel-input:focus-within { box-shadow: none; }
</style>
<style scoped>
  h2 { font-size: 19px; font-weight: 500; color: var(--violet3); margin-bottom: 16px; }
  /*.rsvp form { display: flex; flex-direction: column; }*/
  form button { margin-top: 16px; }
  button { background: var(--violet3); }
  
  .end { font-weight: 500; font-size: 20px; color: var(--violet3) }
  
  /*.rsvp[data-formActive] { position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 11; padding-bottom: 64px; }*/
  /*.rsvp[data-formActive] form { overflow-y: auto; height: 100%; position: absolute; top: 0; bottom: 0; left: 0; right: 0; padding: 36px 24px; }*/
  
  .close { position: absolute; top: 20px; right: 20px; fill: var(--violet3); cursor: pointer }
  
  .check { display: flex; align-items: center }
  .check input { width: 18px; height: 18px; margin-right: 10px; }
  
  .rsvp { background: #efe7e4; border-bottom: 1px solid #ddd3d0; display: flex; justify-content: center; padding: 36px 24px; }
  
  .rsvp[data-formActive] { position: fixed; top: 0; bottom: 0; right: 0; left: 0; background: #efe7e4; z-index: 10; overflow-y: auto; display: block }
  .rsvp[data-formActive] form { display: flex; flex-direction: column }
  /*.rsvp[data-formActive] input { width: 100%; }*/



  @media only screen and (min-width: 800px) {
    
    /*.rsvp { overflow-y: auto }*/
    /*.rsvp form { width: 500px; height: 100%; margin: auto; position: relative!important; overflow-y: visible!important; }*/

    .rsvp[data-formActive] { display: flex; }
    .rsvp[data-formActive] form { width: 500px; margin: auto; }

      /*.rsvp form { display: flex; justify-content: center; width: 500px; margin: auto }*/
    
    /*.rsvp[data-formActive] { position: relative; }*/
    .close { width: 24px; height: 24px; top: 24px; right: 24px; }
  }

  
</style>
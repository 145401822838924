import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from "@/store";


Vue.config.productionTip = false

import VueRellax from 'vue-rellax'
Vue.use(VueRellax)

import { init } from './creative/init'

import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'

import VueYoutube from 'vue-youtube'

init().then(() => {
  
  Vue.use(VueYoutube)
  Vue.use(VueTelInput)
  
  new Vue({
    router, store, render: h => h(App)
  }).$mount('#app')

})
<template>
  <div class="creativeMain">
    
    <div class="editButtons" v-if="$store.state.creativeEdit.admin">
      
    
<!--      <div class="editButton" v-if="$store.state.creativeEdit.admin" @click="editExitConfirmEtat = true; $canScroll = false">-->
<!--        SEO -->
<!--      </div>-->
      <div class="editButton" @click="editExitConfirmEtat = true; $canScroll = false">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10 9.408l2.963 2.592-2.963 2.592v-1.592h-8v-2h8v-1.592zm-2-4.408v4h-8v6h8v4l8-7-8-7zm6-3c-1.787 0-3.46.474-4.911 1.295l.228.2 1.396 1.221c1.004-.456 2.114-.716 3.287-.716 4.411 0 8 3.589 8 8s-3.589 8-8 8c-1.173 0-2.283-.26-3.288-.715l-1.396 1.221-.228.2c1.452.82 3.125 1.294 4.912 1.294 5.522 0 10-4.477 10-10s-4.478-10-10-10z"/></svg>
      </div>
    </div>
    
    
    <div class="popHover animIn" v-if="editExitConfirmEtat">
      <div class="bg"></div>
      <div class="title">Vous sortez du mode édition</div>
      <div class="buttons">
        <div class="boutonCreative big" @click="logout">Voir le site modifié</div>
        <div class="boutonCreative big sub" @click="toBo">Ouvrir le Backoffice</div>
      </div>
      <svg class="close" @click="editExitConfirmEtat = false; $canScroll = true" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"/></svg>
    </div>
    
<!--    <seo></seo>-->
    
  </div>
</template>

<script>
export default {
  name: "creativeMain",
  data() {
    return {
      editExitConfirmEtat: false  
    }
  },
  methods: {
    logout() {
      sessionStorage.removeItem('creative_edit')
      location.reload()
    },
    toBo() {
      sessionStorage.removeItem('creative_edit')
      // window.close();
      location.href = 'https://creative.maboutique.click/'
    }
  }
}
</script>

<style scoped>
  .editButtons { position: fixed; bottom: 24px; left: 24px; display: flex; flex-direction: column }

  .editButton { border: 1px solid #444; background: rgba(255,255,255,.6); width: 50px; height: 50px; display: flex; align-items: center; justify-content: center;
    border-radius: 50%; fill: #444; transition: all .15s ease; cursor: pointer; margin-top: 6px;
  }
  .editButton:hover { background: rgba(255,255,255,.8) }
  
  
  .popHover { position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 150; display: flex; align-items: center; justify-content: center; flex-direction: column; padding: 24px }
  .popHover .title { font-weight: 500; color: #fff; font-size: 38px; line-height: 40px; text-align: center; margin-bottom: 36px; }
  .popHover .buttons { display: flex; flex-direction: column; }
  .popHover .buttons .boutonCreative { margin-bottom: 8px; }
  .popHover .bg { position: absolute; top: 0; right: 0; bottom: 0; left: 0;
    background: radial-gradient(#41b883, #5fbe93); z-index: -1; opacity: .95;
  }
  
  .close { position: absolute; top: 24px; right: 24px; fill: #fff; z-index: 2; cursor: pointer }

  .boutonCreative { padding: 0 20px; height: 40px; display: flex; align-items: center; justify-content: center; background: #fff; border: 1px solid transparent; box-shadow: none; color: #41b883;
    font-weight: 500; font-size: 0.875rem; fill: #fff; font-family: inherit; white-space: nowrap; border-radius: 5px; transition: all .15s ease; cursor: pointer; outline: none;
  }
  .boutonCreative.big { font-weight: 500; font-size: 17px; height: 48px; padding: 0 12px }
  .boutonCreative:not(.sub):hover { border-color: #fff; background: transparent; color: #fff; fill: #fff }
  
  .boutonCreative.sub { border-color: #fff; background: transparent; color: #fff; fill: #fff }
  .boutonCreative.sub:hover { background: rgba(255,255,255,.2); color: #fff; fill: #fff }


  .animIn { animation: animIn .25s ease; }
  .animInDelay1 { animation: animIn .25s .1s ease; opacity: 0; animation-fill-mode: forwards }
  .animInDelay2 { animation: animIn .25s .2s ease; opacity: 0; animation-fill-mode: forwards }
  @keyframes animIn {
    0% { opacity: 0; transform: translateY(1%) }
    100% { opacity: 1; transform: translateY(0%) }
  }


  @media only screen and (min-width: 800px) {

    .popHover .buttons { flex-direction: row }
    .popHover .buttons .boutonCreative { margin-bottom: 0; margin-right: 16px; }
    .popHover .buttons .boutonCreative:last-child { margin-right: 0; }
  }
  
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "../views/Home.vue";

import creative from './creative'

Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/:catchAll(.*)",
        component: () => import('../views/404.vue'),
    },
];

const router = new VueRouter({
    mode: 'history',
    scrollBehavior (to, from, savedPosition) {
        if (to.matched.some((m) => m.meta.scrollToTop)) {
            return { x: 0, y: 0}
        }
        else
            return !savedPosition ? { x: 0, y: 0 } : savedPosition
    },
    routes: [
        ...creative,
        ...routes
    ]
});

export default router;
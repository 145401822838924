<template>
  <div>
    <div class="site">
      <router-view />
      
      <creativeMain></creativeMain>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      
    }
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Bar Mitzva Isaac',
    // all titles will be injected into this templat
  },
  watch: {
    $canScroll() {
      if(this.$canScroll) {
        document.querySelector('body').classList.remove('noScroll')
      }
      else
        document.querySelector('body').classList.add('noScroll')
    }
  },
  mounted() {
    
  },
  components: {
    
  }
}
</script>

<style>

:root {
  --bgBlack: #191919;
  --bgWhite: #fff;
  --bgGris: #3f3f3f;
  --txtBlack: #000;
  --txtGris: #666;
  
  --violet1: #662962;
  --violet2: #4d2864;
  --violet3: #7a3b50;
  --turq1: #20c3b2;
  --or: #bc8e43;
  
  --sable: #cba078;
  --sable2: #dda795;
  
  --font-sans: -apple-system,system-ui,BlinkMacSystemFont,"Helvetica Neue","Helvetica",sans-serif;
}
*, :after, :before {
  box-sizing: border-box;
}
html { -webkit-text-size-adjust: 100%; }
body, html { margin: 0; padding: 0; text-rendering: optimizeLegibility; font-family: var(--font-sans);
  -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale }
html, input { font-feature-settings: "case" 1,"rlig" 1,"calt" 0; font-size: 16px; line-height: 23px;
  font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji; }

body{ font-family: system-ui,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji; font-size: 16px }
body { font-family: inherit; }

/*html,body { cursor: url('./assets/curseur.png'), default; }*/

body.noScroll{ overflow-y: hidden }

a { text-decoration: inherit; color: inherit }

header { display: flex; justify-content: space-between; align-items: center; height: 88px; position: fixed; top: 0; right: 0; left: 0; background: #fff; color: #212529; padding: 0 24px;
  z-index: 5; 
  box-shadow: rgba(0,0,0,0.02) 0px 30px 30px,rgba(0,0,0,0.03) 0px 0px 8px,rgba(0,0,0,0.05) 0px 1px 0px;
}
header .logo { width: 25%; }
header .logo img { height: 30px; }

header .right { width: 25%; display: flex; justify-content: flex-end; align-items: center }
header .right .pro { margin-right: 1rem; }
header .right a { border: 1px solid #000; /*background: var(--bgGris);*/ margin-right: 1rem; border-radius: 1px; font-size: 16px; font-weight: 500; padding: 8px 14px }
header .right .ico { margin-left: 1.5rem; position: relative; top: 3px; cursor: pointer; position: relative; flex: 0 0 auto; }
header .right .ico .indicator { position: absolute; border: 1px solid #f1f3f5; background: #000; color: #fff; width: 1.25rem; height: 1.25rem; top: .75rem; right: .75rem;
  line-height: 1rem; font-size: .75rem; border-radius: 100%; display: flex; align-items: center; justify-content: center; font-weight: 700; padding-left: 2.5px; padding-right: 2.5px;
}
header .right .ico:hover svg { color: #868e96 }
header .right .ico.profil { width: 32px; height: 32px; background: linear-gradient(140deg, rgb(218, 60, 60), rgb(121, 40, 202) 100%); border-radius: 100%; top: 2px }

header .centre { font-size: 16px; font-weight: 500; height: 100%; display: flex; }
header .centre a { margin-right: 16px; height: 100%; display: flex; align-items: center }
header .centre a:last-child { margin-right: 0; }

.bouton, button { background: #000; font: inherit; border-radius: .125rem; cursor: pointer; border-width: 1px; border-style: solid; border-color: transparent; display: inline-flex; align-items: center; justify-content: center;
  padding: 1rem 2.5rem; line-height: 1.5rem; font-weight: 600; color: #f1f3f5; text-transform: uppercase; transition: all .15s cubic-bezier(.4,0,.2,1); 
}
.bouton.small, button.small { padding: 0 1rem; height: 42px; text-transform: inherit; }
.bouton:not([data-disabled]):hover, button:not([data-disabled]):hover { background: transparent; border-color: #000; color: #000 }
.bouton.secondary, button.secondary { background: transparent; border-color: #000; color: #000 }
.bouton.secondary:not([data-disabled]):hover, button.secondary:not([data-disabled]):hover { background: #000; border-color: transparent; color: #fff }
.bouton[data-disabled] { opacity: .15; cursor: not-allowed }

input, textarea, select, #card { background: #fff; border: 1px solid #dee2e6; font-family: inherit; padding: .5rem 2.5rem .5rem 1.5rem; color: #868e96; margin-bottom: .75rem; line-height: 24px; }
input:focus, textarea:focus, select:focus, #card:focus { outline: 2px solid transparent; outline-offset: 2px; box-shadow: 0 0 0 2px #e9ecef }
#card { padding: .7255rem .5rem;  }

textarea.edit:not([data-design]), input.edit:not([data-design]) { color: inherit; font-size: inherit; font-weight: inherit; line-height: inherit; padding: 0; margin: 0; border: inherit; display: inherit;
  font: inherit; height: inherit; min-height: inherit; width: 100%; background: none; text-align: inherit; text-transform: inherit;
}
label { font-weight: 500; font-size: 13px; color: #666; margin-bottom: 8px; display: flex; }

.error { color: #da3c3c; border: 1px solid #da3c3c; padding: .75rem; margin-bottom: .75rem; }

h1, h2, h3, h4, h5, h6 { font-size: inherit; line-height: inherit; color: inherit; font-weight: inherit; font-family: inherit; padding: 0; margin: 0 }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: rgba(26,26,26,.9);
  transition: background-color 100000000s,box-shadow 0s linear;
  box-shadow: 0 1px 1.5px 0 rgb(0 0 0 / 5%), inset 0 0 0 1000px #fefbe7;
}



.fadeIn { animation: fadeIn .25s ease; }
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}



@media only screen and (max-width: 900px) {
  
  .hideMobile { display: none }
  
  /*header { height: 300px; flex-direction: column }*/
  
}

</style>

<template>
  <div class="elem">
    <input v-if="$store.state.creativeEdit.admin && (!type || type === 'value')" :data-design="design" class="edit" type="text" v-model="value" placeholder="Inserez un texte">

<!--    <resizableTextarea v-else-if="$store.state.creativeEdit.admin && type && type === 'textarea'">-->
    <textarea v-else-if="$store.state.creativeEdit.admin && type && type === 'textarea'" :data-design="design" class="edit" v-model="value" v-autogrow placeholder="Inserez un texte"></textarea>

<!--    </resizableTextarea>-->
    
    <div v-else-if="markdown" v-html="compiledMarkdown(value)"></div>
    <div v-else>{{ value }}</div>

    
    
  </div>
</template>

<script>
import marked from 'marked'
import DOMPurify from 'dompurify';
export default {
  data() {
    return {
      value: null,
      lastValue: null,
      interval: null
    }
  },
  props: [ 'text', 'type', 'k', 'design', 'markdown' ],
  methods: {
    compiledMarkdown: function(value) {

      marked.Renderer.prototype.paragraph = (value) => {
        if (value.startsWith("<img")) {
          return value + "\n";
        }
        return value + "<br><br>";
      };
      let m = marked(value)
      m = m.substring(0, m.length - 8);
      m = DOMPurify.sanitize(m);
      return m
      
      
      // let m = marked(value, { 
      //   sanitize: true,
      // });
      // m = m.replace(/^(?:<p>)?(.*?)(?:<\/p>)?$/, "$1")
      // return m
    }
  },
  created() {
    let _this = this
    this.value = this.$content.content[this.k]
    this.lastValue = this.$content.content[this.k]
    this.interval = setInterval(() => {
      if(_this.lastValue !== _this.value) {
        this.$http.put('/project/content', {
          key: _this.k,
          content: _this.value
        })
        console.log(_this.value)
      }
  
      _this.lastValue = _this.value
    }, 500)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  name: "elem"
}
</script>

<style scoped>
  span { display: inherit }
  .elem { white-space: pre-line }
</style>
import axios from 'axios'

export default {
    namespaced: true,
    state: {
        user: null
    },
    mutations: {
        LOGIN(state, data) {
            state.user = data
        }
    },
    actions: {
        autoLogin(store) {
            let token = localStorage.getItem("creative_token");
            if (token) store.dispatch('login', token)
        },
        login(store, token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
            axios.get('/auth').then((data) => {
                store.commit('LOGIN', data.data)
            }).catch((e) => {
                if(e.response && e.response.status === 401)
                    localStorage.removeItem('creative_token')
            })
        }
    }
}
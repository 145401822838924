<template>
  <div class="home">
    <div class="one">
      <div class="bg"></div>
      

       <div></div>
      
      <div class="mid" :data-video="yt">
        <h1>Bar Mitzva Isaac</h1>
        <div class="video" @click="playVideo">
          <img src="../assets/isaac.png" alt="">
          <div class="p">
            <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-3 17v-10l9 5.146-9 4.854z"/></svg>
          </div>
        </div>

        <div class="ytCont">
          <div class="yt">
            <youtube video-id="qiT6PtaK0as" ref="youtube" :player-vars="playerVars" @playing="ytPlaying = true" @paused="ytPlaying = false" @ended="videoEnded" width="100%" height="100%"></youtube>
<!--            <iframe src="https://www.youtube-nocookie.com/embed/qiT6PtaK0as?rel=0&iv_load_policy=0&playsinline=1&showinfo=0&loop=1&playlist=qiT6PtaK0as&autoplay=true" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
          </div>
        </div>
        
      </div>
      
      <div class="bottom">
        <p><elem k="4e75f438a9164f4893604e4311fcba88" type="textarea" markdown="true"></elem></p>
      </div>
    </div>

    <div class="two">
      <div class="padding">
        <elem k="cedc3c342dfa488891cf7f7965f5928a" type="textarea" markdown="true" class="pres"></elem>
      </div>
      
      <div class="mood">
        <h2>MoodBoard</h2>
        <div class="int">
          <div class="img vert"><img src="../assets/mood/1.jpg" alt=""></div>
          <div class="img vert"><img src="../assets/mood/2.jpg" alt=""></div>
          <div class="img"><img src="../assets/mood/3.png" alt=""></div>
          <div class="img vert"><img src="../assets/mood/4.png" alt=""></div>
          <div class="img"><img src="../assets/mood/5.png" alt=""></div>
          <div class="img"><img src="../assets/mood/6.png" alt=""></div>
          <div class="img vert"><img src="../assets/mood/7.jpg" alt=""></div>
          <div class="img vert"><img src="../assets/mood/8.jpg" alt=""></div>
          <div class="img"><img src="../assets/mood/9.png" alt=""></div>
          <div class="img vert"><img src="../assets/mood/10.jpg" alt=""></div>
          <div class="img"><img src="../assets/mood/11.png" alt=""></div>
          <div class="img"><img src="../assets/mood/12.png" alt=""></div>
        </div>
      </div>
      
      <rsvp></rsvp>
      <div class="padding">
        <div class="col">
          <div class="left">
            <img src="../assets/gourgoubes2.jpg" alt="">
          </div>
  
          <div class="right txt">
            <elem k="98baccd196eb4493a234f2df29156523" type="textarea" markdown="true"></elem>
          </div>
        </div>

      </div>
      
      <div class="logements">
        <div class="padding">
          
          <div class="top">
            <div class="c">
              <h2>Logements possibles au domaine</h2>
              <div class="resa">Réservez au <a href="tel:0467737018">04 67 73 70 18</a></div>
            </div>
            <div class="c">
              <div class="reduc">
                <span class="text">
                  Bénéficiez d'une remise de 5% avec le code
                </span>
                <span class="code">BM ISAAC</span>
              </div>
            </div>
          </div>
        </div>
          
          <div class="elem">
            <div class="title">Si vous êtes 2, 3 ou 4 max</div>

            <a href="https://www.camping-anglas.com/hebergement/location-tente-camping-famille-riviere-emplacement/" target="_blank">
              <div class="e">L’IGLOO FAMILY <span class="price">± 57€ tarif négocié</span></div>
              <div class="photos">
                
                <div class="img"><img src="https://www.camping-anglas.com/wp-content/uploads/sites/2/2017/06/Notre-Igloo-Family-2.jpg" alt=""></div>
                <div class="img"><img src="https://www.camping-anglas.com/wp-content/uploads/sites/2/2017/06/Notre-Igloo-Family-7.jpg" alt=""></div>
                <div class="img"><img src="https://www.camping-anglas.com/wp-content/uploads/sites/2/2017/06/Notre-Igloo-Family-8.jpg" alt=""></div>
                <div class="img"><img src="https://www.camping-anglas.com/wp-content/uploads/sites/2/2017/06/Notre-Igloo-Family-6.jpg" alt=""></div>
              </div>
            </a>
            
            <a href="https://www.camping-anglas.com/hebergement/nuits-insolites-languedoc-roussillon-roulotte/" target="_blank">
              <div class="e">LA ROULOTTE <span class="price">± 142€ tarif négocié</span></div>
  
              <div class="photos">
                <div class="img"><img src="https://thelisresa.webcamp.fr/media/2020100911014286293183/pict_3_1.jpg?_=1613752110" alt=""></div>
                <div class="img"><img src="https://www.camping-anglas.com/wp-content/uploads/sites/2/2017/12/Roulotte-9.jpg" alt=""></div>
                <div class="img"><img src="https://www.camping-anglas.com/wp-content/uploads/sites/2/2017/12/Chambre-Parents-7.jpg" alt=""></div>
                <div class="img"><img src="https://www.camping-anglas.com/wp-content/uploads/sites/2/2017/12/Cuisine-5.jpg" alt=""></div>
              </div>
            </a>

            <div class="title">Si vous êtes plus de 4</div>

            <a href="https://www.camping-anglas.com/hebergement/camping-bungalow-famille-jardin-tente/" target="_blank">
              <div class="e">LA LODGE FAMILY <span class="price">± 141€ tarif négocié</span></div>
              <div class="photos">
                <div class="img"><img src="https://www.camping-anglas.com/wp-content/uploads/sites/2/2018/10/Vue-densemble-1499-1.jpg" alt=""></div>
                <div class="img"><img src="https://www.camping-anglas.com/wp-content/uploads/sites/2/2017/12/Copie-de-Lodge.jpg" alt=""></div>
                <div class="img"><img src="https://www.camping-anglas.com/wp-content/uploads/sites/2/2018/10/Pce-de-vie-1479-1.jpg" alt=""></div>
                <div class="img"><img src="https://www.camping-anglas.com/wp-content/uploads/sites/2/2018/10/Chambre-enfant-1488-1.jpg" alt=""></div>
              </div>
            </a>
            
            <a href="https://www.camping-anglas.com/hebergement/location-cabane-cevennes/" target="_blank">
              <div class="e">LA CABANE VIGNERONNE <span class="price">± 152€ tarif négocié</span></div>
              <div class="photos">
                <div class="img"><img src="https://www.camping-anglas.com/wp-content/uploads/sites/2/2017/12/IMG_0137.jpg" alt=""></div>
                <div class="img"><img src="https://www.camping-anglas.com/wp-content/uploads/sites/2/2017/04/IMG_0059.jpg" alt=""></div>
                <div class="img"><img src="https://www.camping-anglas.com/wp-content/uploads/sites/2/2017/12/IMG_0057.jpg" alt=""></div>
                <div class="img"><img src="https://www.camping-anglas.com/wp-content/uploads/sites/2/2017/12/IMG_0031.jpg" alt=""></div>
              </div>
            </a>

            <a href="https://www.camping-anglas.com/hebergement/location-cottage-nature-herault/" target="_blank">
              <div class="e">LE COTTAGE NATURE <span class="price">± 161€ tarif négocié</span></div>
              <div class="photos">
                <div class="img"><img src="https://www.camping-anglas.com/wp-content/uploads/sites/2/2017/06/Ext%C3%A9rieur-7-1-e1501244869121.jpg" alt=""></div>
                <div class="img"><img src="https://www.camping-anglas.com/wp-content/uploads/sites/2/2018/10/Ext%C3%A9rieur-1534-2.jpg" alt=""></div>
                <div class="img"><img src="https://www.camping-anglas.com/wp-content/uploads/sites/2/2018/10/Pi%C3%A8ce-de-vie-1552-1.jpg" alt=""></div>
                <div class="img"><img src="https://www.camping-anglas.com/wp-content/uploads/sites/2/2018/10/Chambre-adulte-1555-2.jpg" alt=""></div>
              </div>
            </a>

            <a href="https://www.camping-anglas.com/hebergement/location-wine-cottage-herault-nature/" target="_blank">
              <div class="e">LE WINE COTTAGE <span class="price">± 199€ tarif négocié</span></div>
              <div class="photos">
                <div class="img"><img src="https://www.camping-anglas.com/wp-content/uploads/sites/2/2020/12/Wine-Cottage-Domaine-Anglas-Location-Insolite.jpg" alt=""></div>
                <div class="img"><img src="https://www.camping-anglas.com/wp-content/uploads/sites/2/2018/10/Terrasse-1658-1.jpg" alt=""></div>
                <div class="img vert"><img src="https://www.camping-anglas.com/wp-content/uploads/sites/2/2017/04/Chambre-2.jpg" alt=""></div>
                <div class="img vert"><img src="https://www.camping-anglas.com/wp-content/uploads/sites/2/2017/04/Chambre-1.jpg" alt=""></div>
              </div>
            </a>

            <a href="https://www.camping-anglas.com/hebergement/cottage-luxe-pilotis-foret-vue-panoramique-riviere/" target="_blank">
              <div class="e">LE COTTAGE LUXE <span class="price">± 199€ tarif négocié</span></div>
              <div class="photos">
                <div class="img"><img src="https://thelisresa.webcamp.fr/media/2020100911014286293183/pict_13_1.jpg?_=1613752656" alt=""></div>
                <div class="img vert"><img src="https://www.camping-anglas.com/wp-content/uploads/sites/2/2018/10/Pi%C3%A8ce-de-vie-1595-1.jpg" alt=""></div>
                <div class="img vert"><img src="https://www.camping-anglas.com/wp-content/uploads/sites/2/2018/10/Terrasse-1645-1.jpg" alt=""></div>
                <div class="img vert"><img src="https://www.camping-anglas.com/wp-content/uploads/sites/2/2020/12/cottage-luxe-hebergement-insolite-domaine-anglas-brissac-herault-scaled.jpg" alt=""></div>
              </div>
            </a>


            <div class="title">Si vous êtes 6 ou 7</div>

            <a href="https://www.camping-anglas.com/hebergement/location-chalet-cevennes-famille/" target="_blank">
              <div class="e">LE CHALET NATURE HOME <span class="price">± 175€ tarif négocié</span></div>
              <div class="photos">
                <div class="img"><img src="https://thelisresa.webcamp.fr/media/2020100911014286293183/pict_4_1.jpg?_=1613752169" alt=""></div>
                <div class="img"><img src="https://www.camping-anglas.com/wp-content/uploads/sites/2/2017/12/DSCN8523.jpg" alt=""></div>
                <div class="img"><img src="https://www.camping-anglas.com/wp-content/uploads/sites/2/2017/06/Photos-Canop%C3%A9e-092.jpg" alt=""></div>
                <div class="img"><img src="https://www.camping-anglas.com/wp-content/uploads/sites/2/2017/06/Photos-Canop%C3%A9e-090.jpg" alt=""></div>
                <div class="img vert"><img src="https://www.camping-anglas.com/wp-content/uploads/sites/2/2017/06/Photos-Canop%C3%A9e-091.jpg" alt=""></div>
              </div>
            </a>
            <a href="https://www.camping-anglas.com/hebergement/gite-sud-france-famille-terrasse-riviere/" target="_blank">
              <div class="e">LE GITE <span class="price">± 209€ tarif négocié</span></div>
              <div class="photos">
                <div class="img"><img src="https://thelisresa.webcamp.fr/media/2020100911014286293183/pict_11_1.jpg?_=1613752546" alt=""></div>
                <div class="img"><img src="https://www.camping-anglas.com/wp-content/uploads/sites/2/2018/10/Salon-1830-2.jpg" alt=""></div>
                <div class="img"><img src="https://www.camping-anglas.com/wp-content/uploads/sites/2/2018/10/Pi%C3%A8ce-de-vie-1828-1.jpg" alt=""></div>
                <div class="img"><img src="https://www.camping-anglas.com/wp-content/uploads/sites/2/2018/10/Chambre-enfant-1868-1.jpg" alt=""></div>
                <div class="img"><img src="https://www.camping-anglas.com/wp-content/uploads/sites/2/2018/10/Chambre-N%C2%B0-2-1901-1.jpg" alt=""></div>
              </div>
            </a>
            
          </div>

        <rsvp class="rsvpBottom"></rsvp>
        </div>
    </div>
    
<!--    <div class="pop" v-if="yt">-->
<!--      <div class="bg" @click="yt = false"></div>-->
<!--      <div class="int">-->
<!--        <div class="yt">-->
<!--          <iframe src="https://www.youtube-nocookie.com/embed/qiT6PtaK0as?rel=0&iv_load_policy=0&playsinline=1&showinfo=0&loop=1&playlist=qiT6PtaK0as&autoplay=true" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    
  </div>
</template>

<script>
import rsvp from '@/components/site/rsvp'
export default {
  name: "Home",
  components: { rsvp },
  data() {
    return {
      ytPlaying: false,
      ytScrollPaused: false,
      yt: false,
      playerVars: {
        inline: 1
      }
    }
  },
  mounted() {
    let _this = this
    window.addEventListener('scroll', function() {
      if(window.scrollY > 400 && _this.yt && _this.ytPlaying) {
        _this.ytScrollPaused = true 
        _this.$refs.youtube.player.pauseVideo()
      }
      else if(_this.yt && !_this.ytPlaying && window.scrollY < 400 && _this.ytScrollPaused) {
        _this.ytScrollPaused = false
        _this.$refs.youtube.player.playVideo()
      }
    })
  },
  created() {
    
  },
  methods: {
    playVideo() {
      let _this = this
      this.yt = true
      // setTimeout(() => {
        _this.$refs.youtube.player.playVideo()
        _this.$refs.youtube.player.seekTo(0)
      // }, 300)
    },
    videoEnded() {
      this.yt = false
    }
  }
}
</script>

<style>
  .ytCont .yt iframe { position: absolute; top: 0; right: 0; bottom: 0; left: 0; width: 100%; height: 100%; }
</style>
<style scoped>

  .rsvpBottom { padding-bottom: 58px; }

  .pop { position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 10; display: flex; align-items: center; justify-content: center; }
  .pop .int { width: 100%; display: flex; align-items: center; justify-content: center; position: relative; }
  .pop .yt { width: 100%; padding-bottom: 100%; height: 0; }
  .pop .yt iframe { position: absolute; top: 0; right: 0; bottom: 0; left: 0; width: 100%; height: 100%; }
  .pop .bg { position: absolute; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0,0,0,.5) }


  .ytCont { display: flex; align-items: center; justify-content: center; position: relative; }
  .ytCont .int { width: 100%; display: flex; align-items: center; justify-content: center; position: relative; }
  .ytCont .yt { width: 100%; padding-bottom: 100%; height: 0; }
  .ytCont .yt iframe { position: absolute; top: 0; right: 0; bottom: 0; left: 0; width: 100%; height: 100%; }
  .ytCont .bg { position: absolute; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0,0,0,.5) }

  video {  }
  
  .home .one { display: flex; align-items: center; justify-content: space-between; padding: 48px 0; flex-direction: column; 
    background: linear-gradient(120deg, var(--violet1), #99552d); position: relative; overflow: hidden;
  }
  .home .one .bg { background: url(../assets/1526740922.jpg) center bottom; position: absolute; top: 0; right: 0; bottom: 0; left: 0; opacity: .05; }
  
  .home .one .mid { margin-bottom: 48px; position: relative; z-index: 1 }
  .home .one .mid[data-video] .video { opacity: 0; display: none }
  .home .one .mid:not([data-video]) .ytCont { display: none }
  
  
  
  .home .one .bottom { position: relative; z-index: 1; width: 100%; }
  
  h1 { font-size: 58px; line-height: 48px; margin-bottom: 48px; color: var(--sable2); font-family: "Orienta"; text-align: center; }
  
  p { text-align: center; color: var(--sable2); font-family: "Orienta"; padding: 0 16px }
  
  .video { width: 100%; padding-bottom: 56.25%; border-radius: 2px; cursor: pointer;
    background: url(../assets/sable.jpg) var(--sable); background-size: cover;
    position: relative; }
  .video img { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); height: 100%; }
  .video svg { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); fill: #fff; }
  .video .p { animation: scale 1s infinite; transform-origin: center; position: absolute; top: 0; bottom: 0; left: 0; right: 0; z-index: 5 }

  .two { background: #f6f1ef; display: flex; flex-direction: column }
  .two .padding { padding: 32px 24px; }
  .two img { width: 100%; margin-bottom: 24px; }
  .two .col { margin-top: 0px; }
  
  @keyframes scale {
    0% { transform: scale(1) }
    100% { transform: scale(1.3) }
  }
  
  .logements { background: #f1e4df; border-top: 1px solid #ddd3d0; }
  .logements h2 { font-weight: bold; font-size: 20px; text-align: center; margin-bottom: 4px; }
  .logements .resa { font-weight: bold; font-size: 18px; text-align: center;  color: var(--violet3)  }
  .logements .reduc { display: flex; background: var(--or); color: #fff; line-height: 18px; margin: 16px 0; }
  .logements .reduc .text { padding: 6px; width: 60%; }
  .logements .reduc .code { width: 40%; padding: 0 16px; background: rgba(255,255,255,.3); display: flex; align-items: center; justify-content: center; font-weight: bold; }
  
  .logements .elem .title { height: 50px; display: flex; align-items: center; font-weight: bold; font-size: 20px; color: #fff; background: var(--violet3); padding: 0 16px; margin-top: 16px; }
  .logements .elem .title:first-child { margin-top: 0; }
  .logements .elem .e { height: 50px; display: flex; align-items: center; padding: 0 16px; background: #f5eeeb; justify-content: space-between; font-weight: 500; }
  .logements .elem .e .price { color: var(--or); font-size: 14px }
  .logements .elem .photos { width: 100%; overflow: auto; display: flex; margin-bottom: 16px; }
  .logements .elem .photosInt { display: flex; }
  .logements .elem .photos .img { width: 80%; flex: 0 0 auto; margin-bottom: 0; margin-right: 4px; position: relative; height: 0; padding-bottom: 56.25%; overflow: hidden }
  .logements .elem .photos .img img { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); height: 100%; }
  .logements .elem .photos .img.vert img { height: auto; width: 100%; }



  .mood { overflow: hidden; width: 100%; }
  .mood h2 { font-weight: 500; font-size: 20px; color: var(--violet3); padding: 0 24px 24px }
  .mood .int { width: 100%; overflow: auto; display: flex; }
  .mood .int .img { width: 70%; flex: 0 0 auto; margin-bottom: 0; margin-right: 4px; position: relative; height: 0; padding-bottom: 70%; overflow: hidden }
  .mood .int .img img { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); height: 100%; margin-bottom: 0; }
  /*.mood .int .img.vert img { height: auto; width: 100%; flex: 0 0 auto; }*/
  
  @media only screen and (min-width: 800px) {

    

    .ytCont { width: 80%; margin: auto }

    .pop .int { width: 50%; }
    /*.logements { background: #242225 }*/
    
    .video svg { width: 86px; height: 86px; }
    
    .home .one { min-height: inherit; padding-bottom: 48px; }
    .home .one p { font-size: 18px; width: 60%; margin: auto }
    .home .one .mid { width: 50%; margin-bottom: 48px; }
    
    .pres { font-size: 18px; padding: 64px 0 64px 0; width: 80%; margin: auto; text-align: justify; }
    
    .padding { width: 70%; margin: auto }
    
    .col { display: flex; align-items: center; margin-bottom: 32px; margin-top: 32px!important; } 
    .col .left { width: 50%; }
    .col .left img { margin-bottom: 0; }
    .col .right { width: 50%; padding-left: 36px; font-size: .9vw; line-height: 1.4vw; }
    
    .two .padding { padding: 48px 0 }
    
    .logements .padding { width: inherit; }
    .logements .reduc { display: flex; margin: auto; text-align: center; height: 40px; align-items: center; margin-top: 24px; background: none }
    .logements .reduc .text { width: inherit; color: var(--or); font-weight: 500; margin-right: 16px; }
    .logements .reduc .code { height: 100%; background: var(--or); width: inherit; }
    .logements .top { display: flex; justify-content: space-between; align-items: center; }
    .logements h2 { text-align: left; }
    .logements .resa { text-align: left }
    .logements .padding { padding-left: 0; padding-right: 0; }
    
    .logements { padding: 0 15%; background: linear-gradient(180deg, #f6f5f4, #f1e4df) }
    .logements .elem .title { background: none; color: var(--violet3); padding: 0; font-size: 30px; height: 60px; margin-top: 58px; }
    /*.logements .elem .title:first-child { padding-top: 0; }*/
    .logements .elem .title:before { content: ''; width: 4px; background: var(--violet3); height: 36px; border-radius: 16px; margin-right: 12px; position: relative; top: 1px }
    .logements .elem .photos { overflow: hidden; margin-bottom: 32px; }
    .logements .elem .e { background: none; padding: 0; font-size: 21px; height: 60px; }
    .logements .elem .e .price { font-size: 18px }
    .logements .elem .photos .img { width: calc(100% / 4 - 3px); padding-bottom: 18%;}

    .mood h2 { text-align: center; font-size: 30px; margin-bottom: 16px; }
    .mood .int { flex-wrap: wrap; padding-left: 10px; margin-bottom: 0; }
    .mood .int .img { width: calc(100% / 6 - 10px); padding-bottom: 16%; margin-bottom: 10px; margin-right: 10px; }

  }

  
  
</style>